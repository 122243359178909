import {
  Arrow,
  Content,
  TooltipArrowProps,
  TooltipContentProps,
} from "@radix-ui/react-tooltip"
import { FC } from "react"

import { create } from "src/helpers/bem"

import styles from "./TooltipContent.module.scss"

const bem = create(styles, "TooltipContent")

const defaultContentProps: TooltipContentProps = {
  side: "top",
  sideOffset: 5,
  align: "center",
  alignOffset: -10,
}

const arrowProps: TooltipArrowProps = {
  offset: 10,
  width: 20,
  height: 10,
}

export const TooltipContent: FC<TooltipContentProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <Content
      className={bem(undefined, undefined, className)}
      {...defaultContentProps}
      {...props}
    >
      {children}
      <Arrow {...arrowProps} className={bem("arrow")} />
    </Content>
  )
}
