import {
  AdditionalService,
  Service,
} from "src/helpers/autoservice-core-backend/types"
import { sendTrackingEvent } from "src/helpers/tracking/sendTrackingEvent"

export const useCategorySelectionTrackingEvents = () => {
  function trackServiceSelection(service: Service) {
    sendTrackingEvent.services({
      gaEvent: {
        action: "select_service",
        label: service.title,
        value: service.internalServiceId,
      },
    })
  }

  function trackAdditionalServiceSelection(
    additionalService: AdditionalService,
    selected: boolean,
  ) {
    sendTrackingEvent.services({
      gaEvent: {
        action: selected ? "select_option" : "remove_option",
        label: additionalService.title,
        value: additionalService.internalAdditionalServiceId,
      },
    })
  }

  function trackSubmit(service?: Service) {
    if (!service) {
      return
    }

    sendTrackingEvent.services({
      gaEvent: {
        action: "service_selection_confirmed",
        label: service.title,
        value: service.internalServiceId,
      },
    })
  }

  function trackHuAuSmokeBlowTestClick() {
    sendTrackingEvent.services({
      gaEvent: {
        action: "huau_for_electric_car_clicked",
      },
    })
  }

  return {
    trackServiceSelection,
    trackAdditionalServiceSelection,
    trackSubmit,
    trackHuAuSmokeBlowTestClick,
  }
}
