import { getTranslationMessages } from "src/hooks/translation"
import { Garage } from "src/states/serviceFlow/garageActions"

import { isPitstopGarage } from "./isPitstopGarage"
import { isPWSGarage } from "./isPWSGarage"

export const getReplacementCarDescription = (
  garage?: Garage,
  isPitstopInspectionFlow?: boolean,
) => {
  const messages = getTranslationMessages()
  const { replacementCar } =
    messages.pages.services.flow.service.categoryCard.category

  const isPWS = isPWSGarage(garage?.dmsProviderType)
  const isPitstop = isPitstopGarage(garage?.dmsProviderType)

  if (!garage) return replacementCar.description.common
  if (isPWS) return replacementCar.description.pws
  if (isPitstop && !isPitstopInspectionFlow)
    return replacementCar.description.pitstop
  if (isPitstop && isPitstopInspectionFlow)
    return replacementCar.description.inspectionRequest
  return replacementCar.description.common
}
