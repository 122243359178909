import { FC } from "react"

import { Icon } from "src/components/common/icon"
import { AdditionalService } from "src/helpers/autoservice-core-backend/types"
import { create } from "src/helpers/bem"
import { checkAdditionalServiceType } from "src/helpers/checkAdditionalServiceType"
import { getIsWheelChangeOnlySelected } from "src/helpers/getIsWheelChangeOnlySelected"
import { hasExpressAppointmentAdditionalService } from "src/helpers/isExpressAppointmentSelected"
import { isPitstopGarage } from "src/helpers/isPitstopGarage"
import {
  hasReplacementCarAdditionalService,
  isReplacementCarSelected,
} from "src/helpers/isReplacementCarSelected"
import { useTranslation } from "src/hooks/translation"

import { useServiceFlowData } from "src/states/serviceFlow"

import { CategoryAdditional } from "./CategoryAdditional"
import styles from "./CategoryAdditionals.module.scss"

const bem = create(styles, "CategoryAdditionals")

export type CategoryAdditionalsProps = {
  additionalServices: AdditionalService[]
  selectedAdditionalServices: AdditionalService[]
  categoryName?: string
  packageAdditionalServiceIds?: string[]
  onAdditionalServiceToggle: (
    additionalService: AdditionalService,
    checked: boolean,
  ) => void
  isCheckoutPage?: boolean
}

export const CategoryAdditionals: FC<CategoryAdditionalsProps> = ({
  categoryName,
  additionalServices,
  selectedAdditionalServices,
  packageAdditionalServiceIds,
  onAdditionalServiceToggle,
  isCheckoutPage = false,
}) => {
  const { messages } = useTranslation()
  const translations =
    messages.pages.services.flow.service.categoryCard.categoryAdditionals
  const replacementCarTranslations =
    messages.components.service.steps.summary.replacementCar
  const expressAppointmentTranslations =
    messages.components.service.steps.summary.expressAppointment
  const { inquiry, serviceCategories, detailedGarage } = useServiceFlowData()

  const isReplacementCarRequested = isReplacementCarSelected(serviceCategories)
  const isWheelChangeOnlySelected =
    getIsWheelChangeOnlySelected(serviceCategories)
  const isPitstop = isPitstopGarage(detailedGarage?.garage.dmsProviderType)

  // Hide express termin if it cannot be selected
  const hideExpressTerminOnCheckout =
    isCheckoutPage && (!isPitstop || !isWheelChangeOnlySelected)

  const title =
    additionalServices.length > 1
      ? translations.titles.multiple
      : translations.titles.single

  const isAdditionalServiceIncluded = (id?: string) =>
    !!id && packageAdditionalServiceIds?.includes(id)

  const getIsAdditionalServiceSelected = (id?: string) =>
    !!id &&
    (selectedAdditionalServices.some(
      (selectedAdditionalService) =>
        selectedAdditionalService?.internalAdditionalServiceId === id,
    ) ||
      isAdditionalServiceIncluded(id))

  /**
   * Replacement car tile should be selected if it is selected with any other services
   */
  const checkIfReplacementCarSelected = (
    isReplacementCarAdditionalService: boolean,
  ) => {
    return (
      isReplacementCarAdditionalService &&
      (isReplacementCarRequested ||
        selectedAdditionalServices.some(hasReplacementCarAdditionalService))
    )
  }

  /**
   * Express termin tile is disabled if replacement car is selected
   */
  const checkIfExpressTerminDisabled = (
    isExpressTerminAdditionalService: boolean,
  ) => {
    return (
      isExpressTerminAdditionalService &&
      (isReplacementCarRequested ||
        selectedAdditionalServices.some(hasReplacementCarAdditionalService))
    )
  }

  /**
   * Replacement car tile is disabled if express termin is selected
   */
  const checkIfReplacementCarDisabled = (
    isReplacementCarAdditionalService: boolean,
  ) => {
    return (
      isReplacementCarAdditionalService &&
      selectedAdditionalServices.some(hasExpressAppointmentAdditionalService)
    )
  }

  return (
    <div
      className={bem(undefined, { "no-border": !!isCheckoutPage })}
      data-cy="AdditionalServiceToggle"
    >
      {!isCheckoutPage && (
        <h2 className={bem("title")}>
          <Icon name="kreativitätUndInnovation" className={bem("icon")} />{" "}
          {title}
        </h2>
      )}

      {additionalServices.map((additionalService, index) => {
        if (additionalService.isHiddenInList) {
          return null
        }

        const isExpressTerminAdditionalService =
          checkAdditionalServiceType.isExpressTermin(
            additionalService.internalAdditionalServiceId,
          )

        const isReplacementCarAdditionalService =
          checkAdditionalServiceType.isReplacementCar(
            additionalService.internalAdditionalServiceId,
          )

        if (
          isExpressTerminAdditionalService &&
          (!!inquiry || hideExpressTerminOnCheckout)
        ) {
          return null
        }

        const selected =
          getIsAdditionalServiceSelected(
            additionalService.internalAdditionalServiceId,
          ) || checkIfReplacementCarSelected(isReplacementCarAdditionalService)

        const isExpressTerminDisabled = checkIfExpressTerminDisabled(
          isExpressTerminAdditionalService,
        )
        const isReplacementCarDisabled = checkIfReplacementCarDisabled(
          isReplacementCarAdditionalService,
        )

        const disabled =
          isAdditionalServiceIncluded(
            additionalService.internalAdditionalServiceId,
          ) ||
          isExpressTerminDisabled ||
          isReplacementCarDisabled

        const getTagLabel = () => {
          if (isExpressTerminDisabled) {
            return expressAppointmentTranslations.cannotCombineWithReplacementCar
          }

          if (isReplacementCarDisabled) {
            return replacementCarTranslations.cannotCombineWithExpressAppointment
          }
          return
        }

        const tagLabel = getTagLabel()

        const hasBackground = () => {
          if (
            index === 0 ||
            index % 2 === 0 ||
            isReplacementCarAdditionalService
          )
            return true
        }

        return (
          <>
            {isReplacementCarAdditionalService && (
              <p className={bem("add-serv-title")}>
                {translations.titles.wholeBooking}
              </p>
            )}
            {/** replacement car will always be the last additional service in CF */}
            {!isCheckoutPage &&
              additionalServices.length > 1 &&
              index === 0 && (
                <p className={bem("add-serv-title")}>{categoryName}</p>
              )}
            <CategoryAdditional
              key={additionalService.internalAdditionalServiceId}
              id={additionalService.internalAdditionalServiceId}
              title={additionalService.title}
              description={{
                title: additionalService.description,
                tooltip: !isExpressTerminAdditionalService,
              }}
              selected={selected}
              disabled={disabled}
              icon={isExpressTerminAdditionalService ? "timer" : undefined}
              price={additionalService.price}
              onChange={(checked) =>
                onAdditionalServiceToggle(additionalService, checked)
              }
              recommended={additionalService.recommended}
              inclusive={isAdditionalServiceIncluded(
                additionalService.internalAdditionalServiceId,
              )}
              hasBackground={hasBackground()}
              tagLabel={tagLabel}
              noBorder
            />
          </>
        )
      })}
    </div>
  )
}
