import { ButtonHTMLAttributes, FC, ReactNode } from "react"

import { Separator } from "../separator"
import { Tooltip } from "../tooltip/Tooltip"
import { IconName, Icon } from "src/components/common/icon"
import { Switch } from "src/components/common/switch"
import { formatPriceInformation } from "src/components/core/bookingSummaryCard/helpers/formatPriceInformation"
import { create } from "src/helpers/bem"
import { slugify } from "src/helpers/slugify"

import styles from "./ToggleCard.module.scss"

const bem = create(styles, "ToggleCard")

type ToggleCardProps = Omit<
  ButtonHTMLAttributes<HTMLButtonElement>,
  "onChange"
> & {
  title: string
  description: string
  descriptionOnTooltip?: boolean
  onChange: (checked: boolean) => void
  isSelected: boolean
  disabled?: boolean
  price?: number | null
  icon?: IconName
  tag?: ReactNode
  rightInfo?: ReactNode
  noBorder?: boolean
  hasBackground?: boolean
}

export const ToggleCard: FC<ToggleCardProps> = ({
  onChange,
  isSelected,
  disabled,
  title,
  description,
  descriptionOnTooltip = false,
  icon,
  price,
  tag,
  rightInfo,
  noBorder,
  hasBackground,
  ...attributes
}) => {
  const modifiers = {
    "is-selected": !!isSelected,
    "is-disabled": !!disabled,
    "no-border": !!noBorder,
    "has-background": !!hasBackground,
  }

  return (
    <div className={bem(undefined, modifiers)}>
      <Switch
        {...attributes}
        disabled={disabled}
        classNames={{
          container: bem("switch", modifiers),
          label: bem("switch__label"),
          field: bem("switch__field"),
        }}
        id={attributes.id ?? slugify(title)}
        checked={isSelected}
        onCheckedChange={onChange}
      >
        {icon && <Icon className={bem("switch__icon")} name={icon} />}
        <span className={bem("title", modifiers)}>{title}</span>
        {descriptionOnTooltip && (
          <Tooltip
            side="top"
            trigger={<Icon className={bem("info-icon")} name="info" />}
            content={
              <p className={bem("tooltip-description")}>{description}</p>
            }
          />
        )}
        {!!price && (
          <span className={bem("price")}>{formatPriceInformation(price)}</span>
        )}

        {rightInfo && <div className={bem("right-info")}>{rightInfo}</div>}
      </Switch>

      {!!tag && tag}

      {!descriptionOnTooltip && (
        <>
          <Separator variant="alternative" />
          <p className={bem("description")}>{description}</p>
        </>
      )}
    </div>
  )
}
