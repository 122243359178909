import { FC, PropsWithChildren } from "react"

import { Icon, IconName } from "../icon"
import { create } from "src/helpers/bem"

import styles from "./Tag.module.scss"

const bem = create(styles, "Tag")

export type TagProps = {
  variant:
    | "primary"
    | "secondary"
    | "emphasized"
    | "success"
    | "alternative"
    | "huk"
  className?: string
  icon?: IconName
}

export const Tag: FC<PropsWithChildren<TagProps>> = ({
  variant,
  className,
  children,
  icon,
}) => {
  const modifiers = {
    [variant]: true,
    icon: !!icon,
  }

  return (
    <div className={bem(undefined, modifiers, className)}>
      <div className={bem("content")}>{children}</div>
      {icon && (
        <div className={bem("icon-wrapper")}>
          <Icon name={icon} className={bem("icon")} />
        </div>
      )}
    </div>
  )
}
