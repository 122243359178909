import { FC, ReactNode } from "react"

import { IconName } from "src/components/common/icon"
import { Tag } from "src/components/common/tag"
import { ToggleCard } from "src/components/common/toggleCard/ToggleCard"
import { useTranslation } from "src/hooks/translation"

export type CategoryAdditionalProps = {
  id?: string
  title?: string
  description?: {
    title?: string
    tooltip?: boolean
  }
  icon?: IconName
  selected?: boolean
  disabled?: boolean
  price?: number | null
  onChange: (checked: boolean) => void
  recommended?: boolean
  inclusive?: boolean
  tagLabel?: string
  noBorder?: boolean
  hasBackground?: boolean
  rightInfo?: ReactNode
}

export const CategoryAdditional: FC<CategoryAdditionalProps> = ({
  id,
  title,
  description,
  icon,
  selected = false,
  disabled = false,
  price,
  onChange,
  recommended,
  inclusive,
  tagLabel,
  noBorder,
  hasBackground,
  rightInfo,
}) => {
  const { messages } = useTranslation()
  const translations = messages.components.common.toggleCard

  const renderTag = () => {
    if (tagLabel) return <Tag variant="alternative">{tagLabel}</Tag>

    if (inclusive)
      return (
        <Tag variant="secondary" icon="inclusive">
          {translations.inclusive}
        </Tag>
      )
    if (price === 0)
      return (
        <Tag variant="success" icon="geschenk">
          {translations.free}
        </Tag>
      )
    if (recommended)
      return <Tag variant="emphasized">{translations.recommended}</Tag>

    return
  }

  const cardTag = renderTag()

  return (
    <ToggleCard
      id={id}
      data-cy={id}
      title={title ?? ""}
      description={description?.title ?? ""}
      descriptionOnTooltip={description?.tooltip}
      isSelected={selected}
      icon={icon}
      price={price ?? null}
      disabled={disabled}
      onChange={onChange}
      tag={cardTag}
      rightInfo={rightInfo}
      hasBackground={hasBackground}
      noBorder={noBorder}
    />
  )
}
