import { Root, Thumb } from "@radix-ui/react-switch"
import { FC, ReactNode, ButtonHTMLAttributes, forwardRef } from "react"

import { create } from "src/helpers/bem"

import styles from "./Switch.module.scss"

const bem = create(styles, "Switch")

export type SwitchClassNames = {
  container?: string
  label?: string
  field?: string
}

export type SwitchProps = {
  children: ReactNode
  id: string
  classNames?: SwitchClassNames
  checked?: boolean
  disabled?: boolean
  onCheckedChange?: (checked: boolean) => void
} & ButtonHTMLAttributes<HTMLButtonElement>

export const Switch: FC<SwitchProps> = forwardRef<
  HTMLButtonElement,
  SwitchProps
>(
  (
    {
      children,
      id,
      classNames,
      disabled = false,
      onCheckedChange,
      ...attributes
    },
    ref,
  ) => {
    return (
      <div className={bem(undefined, undefined, classNames?.container)}>
        <label
          htmlFor={id}
          className={bem("label", undefined, classNames?.label)}
        >
          {children}
        </label>
        <Root
          id={id}
          ref={ref}
          disabled={disabled}
          className={bem(
            "field",
            {
              "is-disabled": disabled,
            },
            classNames?.field,
          )}
          onCheckedChange={onCheckedChange}
          {...attributes}
        >
          <Thumb />
        </Root>
      </div>
    )
  },
)

Switch.displayName = "Switch"
