import {
  Root,
  TooltipContentProps,
  TooltipProvider,
  Trigger,
} from "@radix-ui/react-tooltip"
import { FC, ReactNode, useState } from "react"

import { TooltipContent } from "./TooltipContent"

const delayDuration = 400

export type TooltipProps = Omit<TooltipContentProps, "content"> & {
  trigger: ReactNode
  triggerClassName?: string
  content: ReactNode
  replaceTriggerWithChild?: boolean
  children?: undefined
}

/**
 * A radix-ui based tooltip component
 *
 * The position of the tooltip is defined by the element itself based on the availability of the space.
 */
export const Tooltip: FC<TooltipProps> = ({
  trigger,
  triggerClassName,
  content,
  replaceTriggerWithChild = false,
  ...props
}) => {
  const [open, setOpen] = useState(false)

  return (
    <TooltipProvider>
      <Root delayDuration={delayDuration} open={open}>
        <Trigger
          asChild={replaceTriggerWithChild}
          className={triggerClassName}
          type="button"
          onMouseEnter={() => {
            setOpen(true)
          }}
          onMouseLeave={() => {
            setOpen(false)
          }}
          onFocus={() => {
            setOpen(true)
          }}
          onBlur={() => {
            setOpen(false)
          }}
        >
          {replaceTriggerWithChild ? (
            <div tabIndex={0}>{trigger}</div>
          ) : (
            trigger
          )}
        </Trigger>
        <TooltipContent {...props}>{content}</TooltipContent>
      </Root>
    </TooltipProvider>
  )
}
